import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import Project from '../components/project';

export const query = graphql`
  query($slug: String!) {
    projectsJson(slug: { eq: $slug }) {
      title
      description
      intro
      technologies
      image {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      sections {
        heading
        narrative
        image {
          name
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;

const ProjectTemplate = ({ data }) => {
  const project = data.projectsJson;
  const title = project.title;
  const description = project.description;
  const intro = project.intro;
  const sections = project.sections;
  const technologies = project.technologies;

  return (
    <Layout>
      <Project
        title={title}
        description={description}
        intro={intro}
        sections={sections}
        technologies={technologies}
      />
    </Layout>
  );
};

export default ProjectTemplate;
