import React from 'react';
import { Link } from 'gatsby';
import Image from 'gatsby-image';
// import Chip from '@material-ui/core/Chip';

const Project = ({ title, intro, sections, technologies }) => (
  <div className="project">
    <h1>{title}</h1>
    <p>{intro}</p>
    {technologies.length > 0 ? (
      <p>
        <i>
        {'Technologies used: '}
        {technologies.join(', ')}
        </i>
      </p>
      ) : 
      ''}
    <br/>
    {sections.map( (item, key) =>
      <div className="">
        <h3>{item.heading}</h3>
        <p>{item.narrative}</p>
        <Image className="projectImage" fluid={item.image.childImageSharp.fluid} alt={item.image.name} />
      </div>
    )}
    <p>
      {/* <a href={url}>View this project online &rarr;</a> */}
    </p>
    <p>
      <Link style={{ color: '#FF3333', 'padding-bottom': '1rem'}} to="/">&larr; back to recent projects</Link>
    </p>
  </div>
);

export default Project;
